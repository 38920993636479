<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Contas financeiras / {{tituloAcao}}</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div v-if="tituloAcao" class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="custom-control custom-checkbox cursor-pointer">
              <input type="checkbox" v-model="wallet.active" class="custom-control-input">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description"> Ativo</span>
            </label>
          </div>
          <div class="col-12">
            <label class="required">Descrição</label>
            <input type="text" v-model="wallet.description" class="form-control">
          </div>
        </div>

        <div class="d-flex justify-content-end py-2">
          <button type="button" v-if="wallet.id" @click="removeHandle()" class="btn btn-outline-danger me-2">
            <font-awesome-icon :icon="['fas', 'trash']"/>
            Excluir
          </button>
          <button type="button"
              v-if="!wallet.id"
              v-on:click="createHandle()"
              v-bind:disabled="!canSave()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="!!wallet.id"
              v-on:click="saveHandle()"
              v-bind:disabled="!canSave()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'

export default {
  name: 'WalletEdit',
  components: {
  },
  data() {
    return {
      tituloAcao: '',
      id: undefined,
      wallet: {
        id: undefined,
        active: true,
        description: '',
      }
    }
  },
  methods: {
    initNew() {
      this.tituloAcao = 'Cadastrar';
    },

    initEdit() {
      this.tituloAcao = 'Editar';

      api.get(`/wallets/${this.id}`).then(res => {
        loading(false);
        this.wallet = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    removeHandle() {
      dialogo.confirmacao(`Confirma remover o conta <b>${this.wallet.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/wallets/${this.wallet.id}`).then(() => {
          loading(false);

          this.$router.push('/configuracoes/contas-financeiras');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    reset() {
      this.wallet.id = undefined;
      this.wallet.active = true;
      this.wallet.description = '';
    },

    canSave() {
      switch (true) {
        case (this.wallet.description.length < 3):
          return false;

        default:
          return true;
      }
    },

    createHandle() {
      const sendData = {...this.wallet};

      loading(true);
      api.post('/wallets', sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/contas-financeiras');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    saveHandle() {
      const sendData = {...this.wallet};

      loading(true);
      api.put(`/wallets`, sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/contas-financeiras');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    switch (this.$route.name) {
      case 'WalletNew':
        this.initNew();
        break;

      default: {
        this.id = this.$route.params.id;
        if (!this.id) {
          this.$router.push({ path: `/configuracoes/contas-financeiras` });
          return;
        }

        this.initEdit();
      }
    }
  }
}
</script>
